import { AxiosError, AxiosResponse } from "axios";
import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import {
  DomainSettingsRes,
  OAuthRes,
  UserRes,
  CertificatesRes,
  UserBadgeRes,
  SsoDomainSettingsRes,
  SocialDomainSettingsRes,
  LdapSsoRes,
  LdapSsoLoginRes,
  DomainTermsRes,
  RegisterRes,
  BranchSwitchRes,
  CourseCopyFromLoginRes,
} from "types/responses";
import { config, getBaseUrl } from "@config";
import { URLS } from "@constants/urls";
import { getPublicBearerHeaders } from "@utils/helpers";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export type SignUpData = {
  name: string;
  surname: string;
  email: string;
  login: string;
  password: string;
  captcha_token: string;
  custom_fields?: { [key: string]: string };
  session_data?: string;
};

export type LoginPostData = {
  username: string;
  password: string;
};

export type LdapSigninPostData = {
  username: string;
  password: string;
  logout_redirect_url: string;
  branch_id?: string;
};

export const getURL = (): string => {
  const baseUrl = !config.isDev() ? getBaseUrl() : "";
  return `${window.location.hostname}${baseUrl}`;
};

export const signup = async (formData: SignUpData): Promise<RegisterRes> => {
  const res = await HttpClient.post(ENDPOINTS.public.signup, formData, {
    headers: formContentType,
  });

  return res.data._data;
};

export const guestSignup = async (formData: SignUpData): Promise<RegisterRes> => {
  const res = await HttpClient.post(ENDPOINTS.public.guestSignup, formData, {
    headers: getPublicBearerHeaders(),
  });

  return res.data._data;
};

export const signin = async (formData: LoginPostData): Promise<OAuthRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "password");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  bodyFormData.append("username", formData.username);
  bodyFormData.append("password", formData.password);

  const res = await HttpClient.post(ENDPOINTS.public.login, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const refreshToken = async (refreshToken: string): Promise<AxiosResponse> => {
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "refresh_token");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  bodyFormData.append("refresh_token", refreshToken);

  try {
    return await HttpClient.post(ENDPOINTS.public.refreshToken, bodyFormData, {
      headers: formContentType,
    });
  } catch (error) {
    const err = error as AxiosError;

    if (err.response) {
      return err.response;
    }

    throw Error("refreshToken error:" + err.request ? err.request : err.message);
  }
};

export const getDomainSettings = async (): Promise<DomainSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.public.domainSettings);
  return res.data;
};

export const getDomainTerms = async (): Promise<DomainTermsRes> => {
  const res = await HttpClient.get(ENDPOINTS.domainTerms.terms);
  return res.data;
};

export const getSSOAuthUrls = async (
  domain?: string,
  branchId?: string,
): Promise<SsoDomainSettingsRes> => {
  const url = getURL();

  const successUrl = `${url}${URLS.autologin}?token=`;
  const errorUrl = `${url}${URLS.autologin}?error=`;

  const logoutUrl = domain ?? `${url}${URLS.login}`;
  const queryFromBranch = branchId ? `&branch_id=${branchId}` : "";
  const queryStr = `?success_url=${successUrl}&error_url=${errorUrl}&logout_redirect_url=${logoutUrl}${queryFromBranch}`;

  const res = await HttpClient.get(`${ENDPOINTS.public.ssoAuthLinks}${queryStr}`);
  return res.data;
};

export const getSocialDomainSettings = async (): Promise<SocialDomainSettingsRes> => {
  const url = getURL();

  const successUrl = `${url}${URLS.autologin}?token=`;
  const errorUrl = `${url}${URLS.autologin}?error=`;
  const requiresActionUrl = `${url}${URLS.signup}?action=`;
  const queryStr = `?success_url=${successUrl}&error_url=${errorUrl}&requires_action_url=${requiresActionUrl}`;

  const res = await HttpClient.get(`${ENDPOINTS.public.socialDomainSettings}${queryStr}`);
  return res.data;
};

export const getUserProfile = async (): Promise<UserRes> => {
  const res = await HttpClient.get(ENDPOINTS.user.profile);
  return res.data;
};

export const getUserCertificates = async (queryStr = ""): Promise<CertificatesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.certificates}${queryStr}`);

  return res.data;
};

export const getUserBadges = async (userId: string, queryStr = ""): Promise<UserBadgeRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.users.badges(userId)}${queryStr}`);

  return res.data;
};

export const getMyBadges = async (queryStr = ""): Promise<UserBadgeRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.user.badges}${queryStr}`);

  return res.data;
};

// Post without data
export const getAutologinURL = async (): Promise<{ url: string }> => {
  const res = await HttpClient.post(ENDPOINTS.public.autologin);

  return res.data;
};

export const getLdapToken = async (url: string): Promise<LdapSsoLoginRes> => {
  const res = await HttpClient.get(url);

  return res.data;
};

export const postAutologinToken = async (token: string): Promise<OAuthRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("grant_type", "autologin");
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");

  const res = await HttpClient.post(ENDPOINTS.public.autologinToken(token), bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const verifySignUp = async (user_id: string, code: string): Promise<OAuthRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("client_id", "TalentPlus");
  bodyFormData.append("client_secret", "secret");
  bodyFormData.append("user_id", user_id);
  bodyFormData.append("token", code);

  const res = await HttpClient.post(ENDPOINTS.public.verifySignUp, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postLdapSignIn = async (
  data: LdapSigninPostData,
  isLogin = true,
): Promise<LdapSsoRes> => {
  const url = getURL();
  data.logout_redirect_url = isLogin ? `${url}${URLS.login}` : data.logout_redirect_url;

  const res = await HttpClient.post(ENDPOINTS.public.ldapSsoDomainSettings, data);

  return res.data;
};

export const putDomainTerms = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.domainTerms.accept);
};

export const autologinToBranch = async (
  branchId: string,
  loginData: LoginPostData | null,
): Promise<BranchSwitchRes> => {
  const bodyFormData = new FormData();
  loginData && bodyFormData.append("username", loginData.username);
  loginData && bodyFormData.append("password", loginData.password);

  const endpoint = ENDPOINTS.public.branchAutologin(branchId);

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const putTrackTagManager = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.tagManager.track);
};

export const getAutologinToken = async (
  courseId: string,
  token: string,
): Promise<CourseCopyFromLoginRes> => {
  const queryStr = `?token=${token}`;
  const res = await HttpClient.get(`${ENDPOINTS.public.getGoToCourseToken(courseId)}${queryStr}`);

  return res.data;
};
