import React, { FC } from "react";
import { useResponsive, useToggle } from "ahooks";
import { TopBarMenuStyles } from "./TopBarMenu.styles";
import { useQuery } from "react-query";

// Components
import { ProfileMenu } from "./components/ProfileMenu";
import { MessagesMenu } from "./components/MessagesMenu";
import { BranchesMenu } from "./components/BranchesMenu";
import { DiscussionsMenu } from "./components/DiscussionsMenu";
import { ImpersonationBackButton } from "./components/ImpersonationBackButton";
import { StartReverseTrialButton } from "./components/StartReverseTrialButton";
import { StartReverseTrialModal } from "@components/ReverseTrial/StartReverseTrialModal";
import { DaysRemaining } from "@components/ReverseTrial/DaysRemaining/DaysRemaining";

// Stores
import { useConfigurationStore } from "@stores";

// Hooks, utils, etc.
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { getReverseTrialStats } from "@api/reverseTrial";

//Constants
import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";

const TopBarMenu: FC = () => {
  // Responsive breakpoint. sm means until 576px.
  const { md, lg } = useResponsive();
  const { userProfileData, domainSettings } = useConfigurationStore();

  const [isTrialModalOpen, { toggle: toggleTrialModal }] = useToggle(false);

  // Permissions. Some checks are initiated at component level (ex: branches menu, Profile sub-menu items).
  const showImpersonationBackButton = userProfileData?.impersonated;
  const canEnableReverseTrial = userProfileData?.policies.can_enable_reverse_trial;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const showDiscussions = canAccessDiscussions() && md;
  const showMessages = canAccessMessages() && md;
  const showBranches = md;
  const isInTrial = domainSettings?.is_in_trial;
  const { canAccessSubscription } = permissions.accountPermissions;
  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  const { data: reverseTrialStats } = useQuery(
    [queryKeys.reverseTrial.stats],
    () => getReverseTrialStats(),
    {
      enabled: isInTrial,
      select: (data) => data?._data,
    },
  );

  const { days_left: daysLeft = 0, total_days: totalDays = 0 } = reverseTrialStats || {};
  const canSeeTrialRemainingDays =
    isInTrial && reverseTrialStats && canAccessSubscription() && isAdmin;

  return (
    <section data-testid="topbar-menu" css={TopBarMenuStyles}>
      {canEnableReverseTrial && lg && <StartReverseTrialButton onClick={toggleTrialModal} />}
      {canSeeTrialRemainingDays && <DaysRemaining remainingDays={daysLeft} maxDays={totalDays} />}
      {showMessages && <MessagesMenu />}
      {showDiscussions && <DiscussionsMenu />}
      {showBranches && <BranchesMenu />}
      {md && showImpersonationBackButton && <ImpersonationBackButton />}
      <ProfileMenu
        isImpersonated={showImpersonationBackButton}
        toggleTrialModal={toggleTrialModal}
        reverseTrialStats={reverseTrialStats}
      />
      {isTrialModalOpen && (
        <StartReverseTrialModal isOpen={isTrialModalOpen} toggleModal={toggleTrialModal} />
      )}
    </section>
  );
};

export default TopBarMenu;
